import "./Services.scss";
import servImage from "../../assets/images/whiteMach.webp";

function Services() {


  return (
    <>
      <div id="services" className="services">

          <div className="services__heading">
          <h2 className="services__heading--h2">Services</h2>
              <h3 className="services__heading--h3">
                New and Used Vending Machines
              </h3>
              <div className="services__copy">
                We sell new and used vending machines with a 1 year warranty and
                free delivery in the lower mainland
              </div>

              <h3 className="services__heading--h3">Your Location</h3>
              <div className="services__copy">
                Do you need a vending machine for your office, lunchroom, or
                workshop. Discover how our free vending machine service can
                enhance your workplace experience. From satisfying hunger pangs
                to providing quick refreshments, our tailored solutions ensure
                your business runs smoothly. Get in touch today to learn more
              </div>

            <a href="#contact" className="services__action"  >Contact us for details!</a>
          </div>
      
        <img className="services__image" src={servImage} alt="" />
      </div>

    </>
  );
}

export default Services;
