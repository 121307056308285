import "./Main.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Main() {
  const settings = {
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 2500,
    cssEase: "ease-out",
    arrows: false,
  };

  return (
    <>
      <div className="main">
       
        <Slider {...settings}>
          <div className="main__slide  slide1"></div>
          <div className="main__slide  slide2"></div>
          <div className="main__slide  slide3"></div>
          <div className="main__slide  slide4"></div>
          <div className="main__slide  slide5"></div>
          <div className="main__slide  slide6"></div>
        </Slider>
        <div className="main__left">
          <h1 className="main__heading">We Supply</h1>{" "}
          <h1 className="main__heading--sub">Quality Vending Solutions</h1>
          <h3 className="main__sub-heading">In The Vancouver Area</h3>
        </div>
        {/* <img src={vending} alt="Vending machine" className="main__image" /> */}
      </div>
    </>
  );
}

export default Main;
